import styles from './index.module.scss'
import {useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import Link from 'next/link'
import clsx from "clsx";
import Icon from "../../../common/icon";
import {useDispatch} from "react-redux";
import Button from "/components/common/button"
import {getEdost} from "/redux/action-creaters/location";
import {popupTypes} from "/redux/reducers/popupReducer";
import {showPopup} from "/redux/action-creaters/popup";
import {setShowMobileMenu} from "/redux/action-creaters/pageState";
import {useRouter} from "next/router";

export default function MobileMenu({user, contactsData}) {
    const dispatch = useDispatch();
    const router = useRouter()

    const phone = useMemo(() => {
        return contactsData?.PHONE
    }, [contactsData])

    const isAuthorized = useMemo(() => {
        return Boolean(user?.ID)
    }, [user])

    return (
        <div className={styles.TopSections}>

            <ul className={styles.Links}>
                <li>
                    <Link href="/catalog/"><a><Icon  fill={'#93979b'} size="18" id='catalog'/><span>Каталог</span></a></Link>
                </li>
                <li>
                    <Link href="/faq/">Покупателям</Link>
                </li>
                <li>
                    <Link href="/services/">Услуги</Link>
                </li>
                <li>
                    <Link href="/delivery/">Доставка</Link>
                </li>
                <li>
                    <Link href="/reviews/">Отзывы</Link>
                </li>
                <li>
                    <Link href="/articles/">Статьи и новости</Link>
                </li>
                <li>
                    <Link href="/partners/cooperation/">Партнерам</Link>
                </li>
                <li>
                    <Link href="/about/">О компании</Link>
                </li>
                <li>
                    <Link href="/contacts/">Контакты</Link>
                </li>
                <li>
                    <a onClick={() => {
                        if (isAuthorized) {
                            router.push('/personal/')
                        } else {
                            dispatch(setShowMobileMenu(false))
                            dispatch(showPopup(popupTypes.auth))
                        }
                    }}><Icon fill={'#93979b'} size="18"  id='user'/><span>Личный кабинет</span></a>
                </li>
                <li>
                    <Link href="/personal/cart/"><a><Icon size="22" id='cart' customStyles={styles.CartIcon}/><span>Корзина</span></a></Link>
                </li>
                <li>
                    <a onClick={() => {
                        dispatch(setShowMobileMenu(false))
                        dispatch(showPopup(popupTypes.locationNew))
                    }}><Icon fill={'#93979b'} size="20"  id='location'/><span>Москва</span></a>
                </li>
            </ul>


            <div className={styles.Soc}>
                <span>Мы в соцсетях</span>
                <div className={styles.SocList}>
                    <Link href='https://www.instagram.com/farkop.ru/'><a><img src='/soc/2.svg'></img></a></Link>
                    <Link href='https://vk.com/farkopru'><a><img src='/soc/3.svg'></img></a></Link>
                    <Link href='https://www.facebook.com/%D0%A4%D0%B0%D1%80%D0%BA%D0%BE%D0%BF-%D0%A1%D0%9F%D0%B1-451260541645692/'><a><img src='/soc/2.svg'></img></a></Link>
                    <Link href='https://www.youtube.com/channel/UCvIC3w49aEkOLBH4gG4iCbw'><a><img src='/soc/4.svg'></img></a></Link>
                </div>
            </div>

            <div className={styles.Info}>
                <a href="mailto:info@farkop.ru">info@farkop.ru</a>
                <a href={`tel:${phone}`}>{phone}</a>
            </div>
            <div className={styles.Button}>
                <Button
                    onClick={() => {
                        dispatch(setShowMobileMenu(false))
                        dispatch(showPopup(popupTypes.backCall))
                    }}
                    label={'Заказать звонок'}
                />
            </div>
        </div>
    )
}