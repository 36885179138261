import Baltex from "./baltex";
import Layout from "./main";
import {checkIsBaltex, checkIsLanding} from "~/utils";
import Landings from "components/layouts/landings";

export default function LayoutWrapper({ host, children, scripts, isIndex = true, isMain = false }) {
    const isBaltex = checkIsBaltex(host);
    const isLending = checkIsLanding(host);
    return <>
        {isBaltex && (
            <Baltex scripts={scripts} host={host} isIndex={isIndex}>
                {children}

                <span dangerouslySetInnerHTML={{__html: `
                 <style>
                    :root {
                        --font: 'Gilroy';
                        --main-theme: #09080A;
                        --main-theme-2: #09080A;
                        --link-color: #0FB1B1;
                        --main-theme-hover: #333;
                        --main-theme-text: #fff;
                        --main-theme-border-radius: 0px;
                        --main-theme-back: #F2F2F2;
                    }
                </style>
                `}}></span>
            </Baltex>
        )}
        {isLending && (
            <Landings scripts={scripts} host={host} isMain={isMain} isIndex={isIndex}>
                {children}

                <span dangerouslySetInnerHTML={{__html: `
                 <style>
                    :root {
                        --font: 'Golos Text';
                        --main-theme: #FADE74;
                        --main-theme-2: #09080A;
                        --link-color: #09080A;
                        --main-theme-hover: #ffd842;
                        --main-theme-text: #09080A;
                        --main-theme-border-radius: 0px;
                        --main-theme-back: #F2F2F2;
                    }
                </style>
                `}}></span>
            </Landings>
        )}
        {!isBaltex && !isLending && (
            <Layout scripts={scripts} host={host} isIndex={isIndex}>
                {children}

                <span dangerouslySetInnerHTML={{__html: `
                <style>
                    :root {
                        --font: 'Golos Text';
                        --main-theme: #1247a5;
                        --main-theme-2: #1247a5;
                        --link-color: #1247a5;
                        --main-theme-hover: #0634B0;
                        --main-theme-text: #fff;
                        --main-theme-border-radius: 4px;
                        --main-theme-back: #fff;
                    }
                </style>
                `}}></span>
            </Layout>
        )}
    </>
}